import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center bg-white rounded-lg p-8 border-2 border-gray-300" }
const _hoisted_2 = { class: "space-y-8 divide-y divide-gray-200 sm:space-y-5" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "flex flex-row justify-between" }
const _hoisted_5 = { class: "text-lg leading-6 font-medium text-gray-900" }
const _hoisted_6 = { class: "mt-1 max-w-2xl text-sm text-gray-500" }
const _hoisted_7 = { class: "flex flex-row justify-between" }
const _hoisted_8 = { class: "mt-1 max-w-2xl text-sm text-gray-500" }
const _hoisted_9 = { class: "flex flex-col gap-4 sm:gap-0" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "sm:grid sm:grid-cols-3 sm:items-start sm:border-t sm:border-gray-200" }
const _hoisted_12 = {
  for: "treatment.treatment",
  class: "sm:pl-8 bg-gray-50 block h-full text-sm font-medium text-gray-700 p-4 sm:py-5 flex flex-col justify-center imtes-center"
}
const _hoisted_13 = { class: "flex flex-col items-center p-4 gap-2" }
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = { class: "pt-5" }
const _hoisted_16 = { class: "flex justify-end" }
const _hoisted_17 = {
  type: "submit",
  class: "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleQuestion = _resolveComponent("SimpleQuestion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "space-y-8 divide-y divide-gray-200",
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('InfoTreatmentNoStudies.Title')), 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('InfoTreatmentNoStudies.VisitDate')), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('InfoTreatmentNoStudies.PersonalPatientInfo')), 1),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.treatment.date) = $event)),
                type: "date",
                name: "visited",
                id: "visited",
                class: "my-1.5 max-w-lg block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              }, null, 512), [
                [_vModelText, _ctx.treatment.date]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('InfoTreatmentNoStudies.any_treatment'),
              value: _ctx.treatment.any_treatment,
              key: _ctx.treatment.any_treatment,
              onChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.treatment.any_treatment = $event))
            }, null, 8, ["text", "value"])),
            _createVNode(_Transition, {
              "enter-active-class": "animate__animated animate__fadeIn",
              "leave-active-class": "animate__animated animate__fadeOut"
            }, {
              default: _withCtx(() => [
                (_ctx.treatment.any_treatment)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('InfoTreatmentNoStudies.treatment')), 1),
                        _createElementVNode("div", {
                          class: _normalizeClass([{
                      'bg-gray-300': _ctx.subQuestionLvl === 1,
                      'bg-gray-400': _ctx.subQuestionLvl === 2,
                      'bg-gray-500': _ctx.subQuestionLvl === 3,
                      'bg-gray-600': _ctx.subQuestionLvl === 4
                    }, "flex flex-col col-span-2 justify-center bg-gray-200"])
                        }, [
                          _createElementVNode("div", _hoisted_13, [
                            _withDirectives(_createElementVNode("textarea", {
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.treatment.treatment) = $event)),
                              id: "treatment",
                              name: "treatment",
                              rows: "3",
                              class: "max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md",
                              placeholder: 
                          _ctx.$t('InfoTreatmentNoStudies.treatmentPlaceholder')
                        
                            }, "\r\n                      ", 8, _hoisted_14), [
                              [_vModelText, _ctx.treatment.treatment]
                            ])
                          ])
                        ], 2)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.back())),
            type: "button",
            class: "bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }, _toDisplayString(_ctx.$t('InfoTreatmentNoStudies.Back')), 1),
          _createElementVNode("button", _hoisted_17, _toDisplayString(_ctx.$t('InfoTreatmentNoStudies.Save')), 1)
        ])
      ])
    ], 32)
  ]))
}