
import Patient from '@/models/Patient'
import SimpleQuestion from '@/components/SimpleQuestion.vue'
import Swal from 'sweetalert2'
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'

@Options({
  components: {
    SimpleQuestion
  },
  methods: mapActions(['updateTreatment', 'downloadTreatment']),
  computed: mapGetters(['patient', 'treatment'])
})
export default class InfoTreatmentNoStudies extends Vue {
  patient!: Patient
  treatment!: Patient['treatment']

  // Actions
  updateTreatment!: (payload: {
    id: number
    treatment: Patient['treatment']
  }) => Promise<void>

  downloadTreatment!: () => Promise<void>

  async created (): Promise<void> {
    await this.downloadTreatment()
  }

  async submit (): Promise<void> {
    try {
      await this.updateTreatment({
        id: this.patient.id,
        treatment: this.treatment
      })

      await Swal.fire({
        title: this.$t('InfoTreatmentNoStudies.UpdateTitle'),
        confirmButtonText: this.$t('InfoTreatmentNoStudies.UpdateConfirmButton')
      })

      this.$router.push({ name: this.$route.meta.next })
    } catch {
      this.$router.push({ name: 'Logout' })
    }
  }
}
